/****************************************************************************
 *                            .evn配置读取工具类
 ****************************************************************************/
let browser_title = ''
let web_title = ''
let web_ico = ''
let api_base_url = ''
let default_lang = ''
let timezone = null
let rcWebhook = null
let add_agent_phone_limit = null

function getEnvConfig(field) {
  const configs = {}
  try {
    Object.keys(import.meta.env).forEach((key) => {
      if (key.startsWith(field)) {
        const [domain, url] = import.meta.env[key].split(',')
        configs[domain.trim()] = url.trim()
      }
    })
  } catch (err) {
    console.error(err)
  }
  return configs
}

/**
 * 获取API服务器请求地址
 * @returns string
 */
export function getApiBaseUrl() {
  if (api_base_url !== '') {
    return api_base_url
  }

  const config = getEnvConfig('VITE_API_SERVER_')

  const currentFullDomain = window.location.hostname

  // 直接匹配完整域名
  if (config[currentFullDomain]) {
    api_base_url = config[currentFullDomain]
    return api_base_url
  }

  // 有配置默认值
  if (import.meta.env.VITE_API_SERVER) {
    api_base_url = import.meta.env.VITE_API_SERVER.trim()
    return api_base_url
  }

  // 没有对应配置
  return 'http://xx.xx.xx.xx'
}

/**
 * 获取浏览器顶部标题
 * @returns string
 */
export function getBrowserTitle() {
  if (browser_title !== '') {
    return browser_title
  }

  const config = getEnvConfig('VITE_BROWSER_')

  const currentFullDomain = window.location.hostname

  // 直接匹配完整域名
  if (config[currentFullDomain]) {
    browser_title = config[currentFullDomain]
    return browser_title
  }

  // 有配置默认值
  if (import.meta.env.VITE_TAB_NAME) {
    browser_title = import.meta.env.VITE_TAB_NAME.trim()
    return browser_title
  }

  // 没有对应配置
  return 'unknown'
}

/**
 * 获取网页标题
 * @returns string
 */
export function getWebIco() {
  if (web_ico !== '') {
    return web_ico
  }

  const config = getEnvConfig('VITE_ICO_')

  const currentFullDomain = window.location.hostname

  console.log('.....................')
  if (import.meta.env.DEV) {
    web_ico = '/src/assets/'
  } else {
    web_ico = '/ico/'
  }

  // 直接匹配完整域名
  if (config[currentFullDomain]) {
    web_ico += config[currentFullDomain]
    return web_ico
  }

  // 有配置默认值
  if (import.meta.env.VITE_ICO) {
    web_ico += import.meta.env.VITE_ICO.trim()
    return web_ico
  }

  web_ico += 'favicon.ico'

  // 没有对应配置
  return web_ico
}

/**
 * 获取网页标题
 * @returns string
 */
export function getWebTitle() {
  if (web_title !== '') {
    return web_title
  }

  const config = getEnvConfig('VITE_WEB_TITLE_')

  const currentFullDomain = window.location.hostname

  // 直接匹配完整域名
  if (config[currentFullDomain]) {
    web_title = config[currentFullDomain]
    return web_title
  }

  // 有配置默认值
  if (import.meta.env.VITE_WEB_TITLE) {
    web_title = import.meta.env.VITE_WEB_TITLE.trim()
    return web_title
  }

  // 没有对应配置
  return 'unknown'
}

/**
 * 获取默认国际化
 * @returns string
 */
export function getDefaultLang() {
  if (default_lang !== '') {
    return default_lang
  }

  const config = getEnvConfig('VITE_LANGUAGE_')

  const currentFullDomain = window.location.hostname

  // 直接匹配完整域名
  if (config[currentFullDomain]) {
    default_lang = config[currentFullDomain]
    return default_lang
  }

  // 有配置默认值
  if (import.meta.env.VITE_LANGUAGE) {
    default_lang = import.meta.env.VITE_LANGUAGE.trim()
    return default_lang
  }

  // 没有对应配置
  return 'en'
}

/**
 * 获取时区
 * @returns string
 */
export function getTimeZone() {
  if (timezone !== null) {
    return timezone
  }

  const config = getEnvConfig('VITE_TIME_ZONE_')

  const currentFullDomain = window.location.hostname

  // 直接匹配完整域名
  if (config[currentFullDomain]) {
    timezone = parseFloat(config[currentFullDomain])
    return timezone
  }

  // 有配置默认值
  if (import.meta.env.VITE_TIME_ZONE) {
    timezone = parseFloat(import.meta.env.VITE_TIME_ZONE.trim())
    return timezone
  }

  // 没有对应配置
  return 9
}

/**
 * 获取RocketChat请求地址
 * @returns string
 */
export function getRocketChatWebhook() {
  if (rcWebhook !== null) {
    return rcWebhook
  }

  const config = getEnvConfig('VITE_RC_WEBHOOK_')

  const currentFullDomain = window.location.hostname

  // 直接匹配完整域名
  if (config[currentFullDomain]) {
    rcWebhook = config[currentFullDomain]
    return rcWebhook
  }

  // 有配置默认值
  if (import.meta.env.VITE_RC_WEBHOOK) {
    rcWebhook = import.meta.env.VITE_RC_WEBHOOK.trim()
    return rcWebhook
  }

  // 没有对应配置
  return ''
}

/**
 * 获取RocketChat请求地址
 * @returns string
 */
export function getAddAgentPhoneLimit() {
  if (add_agent_phone_limit !== null) {
    return add_agent_phone_limit
  }

  const config = getEnvConfig('VITE_ADD_AGENT_PHONE_')

  const currentFullDomain = window.location.hostname

  // 直接匹配完整域名
  if (config[currentFullDomain]) {
    add_agent_phone_limit = config[currentFullDomain]
    return add_agent_phone_limit
  }

  // 有配置默认值
  if (import.meta.env.VITE_ADD_AGENT_PHONE) {
    add_agent_phone_limit = import.meta.env.VITE_ADD_AGENT_PHONE.trim()
    return add_agent_phone_limit
  }

  // 没有对应配置
  return 'any'
}
